import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CheckoutService } from "./checkout.service";
import { AuthService } from "../../elementar-common/services/auth.service";

import { ToastrService } from 'ngx-toastr';

import { ProductsOrder } from '../../../app/models/ProductsOrder';
import { SimResponse } from '../../../app/models/SimResponse';
import { InquiryResponse, ResponseMessage } from '../../../app/models';
import { Order } from '../../../app/models/Order';
import { Response } from '../../../app/models/Response';

@Component({
  selector: 'elmtr-checkout',
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent implements OnInit {
  currentOrder?: Order;
  cepFormGroup: UntypedFormGroup | undefined;
  isLoading: boolean = false;
  showSelectedOldSim: boolean = false;
  showNewSimSameCEPDifferentValuesMessage: boolean = false;
  showNewSimDiffCEPMessage: boolean = false;
  oldSelectedSim?: SimResponse;
  deliverySimListCep?: string;
  deliverySimList: SimResponse[] = [];

  newSelectedSimItem: SimResponse | null = null;
  
  constructor(
    private checkoutService: CheckoutService, 
    private activatedRoute: ActivatedRoute, 
    private authService: AuthService, 
    private router: Router,
    private toastrService: ToastrService
  ){ }

  ngOnInit(){
    console.log('ngOnInit checkout component');
    console.log('this.activatedRoute.snapshot.params', this.activatedRoute.snapshot.params);
    
    this.checkoutService.getOrderByExtId(this.activatedRoute.snapshot.params['order'], this.activatedRoute.snapshot.params['token'])
      .subscribe((res: Response<Order>) => {
        console.log('this.checkoutService.getOrderByExtId => res', res);
        if (res.operationSuccess) {
          this.currentOrder = this.processAmounts(res.item as unknown as Order);

          if (this.currentOrder.deliverySim) {
            this.cepFormGroup?.controls['cep'].setValue(this.currentOrder.deliverySim?.cepDestination);
            this.newSelectedSimItem = this.oldSelectedSim = JSON.parse(JSON.stringify(this.currentOrder.deliverySim));
            this.showSelectedOldSim = true;
          }
        }
      });

    this.cepFormGroup = new UntypedFormGroup({
      cep: new UntypedFormControl(null)
    })

    this.cepFormGroup.controls['cep'].valueChanges.subscribe((changedValue) => {
      this.newSelectedSimItem = null;
      // CHECK IF THE VALUE OF THE FORM IS THE SAME AS THE ALREADY SAVED ONE
      this.validateShowItems(changedValue);
    })
  }

  /**
   * Description
   * @param {any} formValue:any=this.cepFormGroup?.getRawValue(
   * @returns {any}
   */
  validateShowItems(formValue: any = this.cepFormGroup?.getRawValue()?.cep): void {
    if (formValue == this.currentOrder!.deliverySim?.cepDestination) {
      // IF THERES SIMULATION LIST
      this.showNewSimDiffCEPMessage = false;
      if (this.deliverySimList.length != 0) {
        this.showSelectedOldSim = false;
        if (formValue == this.oldSelectedSim?.cepDestination) {
          // THERE IS A SIMULATION LIST
          this.showNewSimSameCEPDifferentValuesMessage = true;
        } else {
          this.showNewSimSameCEPDifferentValuesMessage = false;
        }
      } else {
        // THEREs NOT A SIMULATION LIST
        this.showSelectedOldSim = true;
        this.showNewSimSameCEPDifferentValuesMessage = false;
      }
    } else {
      this.showSelectedOldSim = false;
      this.showNewSimSameCEPDifferentValuesMessage = false;
      this.showNewSimDiffCEPMessage = true;
    }
  }

  /**
   * Description
   * @param {any} order:Order
   * @returns {any}
   */
  processAmounts(order: Order): Order {
    order.productsList?.forEach((currentProduct: ProductsOrder) => {
      const cpAmount = currentProduct.amount ? currentProduct.amount : 0;
      const cpValue = currentProduct.value ? currentProduct.value : 0;
      currentProduct.total = cpValue * cpAmount;

      if (order.totalProductsValue === undefined) {
        order.totalProductsValue = 0;
      }
      order.totalProductsValue += currentProduct.total;
    });

    order.deliveryValue = order.deliverySim?.value;

    order.totalValue =  order.totalProductsValue! + order.deliveryValue!;
    
    return this.updateTotalValue(order);
  }

  /**
   * Description
   * @returns {any}
   */
  getNewDeliverySimulation(){
    console.log('getNewDeliverySimulation');
    
    this.deliverySimListCep = this.currentOrder!.deliverySim!.cepDestination = this.cepFormGroup?.getRawValue().cep;

    this.checkoutService.getShippingSimulation(this.currentOrder!)
      .subscribe((iqryShippingSim: InquiryResponse<SimResponse>) => {
        console.log(iqryShippingSim);

        if (!iqryShippingSim.operationSuccess) {
          iqryShippingSim.messages.forEach(currentMessage => {
            console.error(`${currentMessage.code} => ${currentMessage.message}`);
            this.toastrService.error(currentMessage.message);
          });
          return;
        }
        
        this.deliverySimList = iqryShippingSim.returnValues!;
        this.validateShowItems();
      });

    
  }

  /**
   * Description
   * @param {any} order:Order
   * @returns {any}
   */
  updateTotalValue(order: Order): Order {
    const totalProd = order?.totalProductsValue ? order?.totalProductsValue : 0;
    const delivery = order?.deliveryValue ? order?.deliveryValue : 0;

    if (order) {
      order.totalValue = totalProd + delivery;
    }

    return order;
  }

  /**
   * Description
   * @returns {any}
   */
  clickPayment(): void {
    
    
    if (!this.newSelectedSimItem) {
      this.toastrService.error('Impossível prosseguir sem uma entrega definida.');
      return;
    }

    console.log(this.newSelectedSimItem);
    this.currentOrder!.deliverySim = this.newSelectedSimItem;

    this.checkoutService.updateSimByOrder(this.currentOrder!)
      .subscribe((returnUpdate: Response<any>) => {
        if (!returnUpdate.operationSuccess) {
          returnUpdate.messages.forEach((currentMessage: ResponseMessage) => {
            this.toastrService.error(currentMessage.message)
          })
          return;
        } else {
          if (this.authService.isLogged()) {
            // [TODO]
            this.router.navigateByUrl('/auth/checkout');
          } else {
            this.router.navigateByUrl('/login', { state: {
              tempUser: this.activatedRoute.snapshot.params['token'],
              tempOrder: this.activatedRoute.snapshot.params['order']
            }});
          }
        }
      }, (err: any) => {
        console.error(err);
        this.toastrService.error(`Erro ao atualizar entrega: ${err.status} => ${err.statusText}`);
      });
  }

  /**
   * Description
   * @param {any} index:number
   * @returns {any}
   */
  selectShippingItem(index: number): void {
    // This cleans the visual mark that shows selected
    for (let currentIndex = 0; currentIndex < this.deliverySimList.length; currentIndex++) {
      const currentDeliverySim = this.deliverySimList[currentIndex];
      currentDeliverySim.isSelected = false;
    }

    // Marks the proper one as selected
    this.deliverySimList[index].isSelected = true;

    // Sets the order delivery value to help the totals calc
    this.currentOrder!.deliveryValue = this.deliverySimList[index].value;

    const totalProd = this.currentOrder!.totalProductsValue ? this.currentOrder!.totalProductsValue : 0;
    const delivery = this.currentOrder!.deliveryValue ? this.currentOrder!.deliveryValue : 0;
   
    // Calc the new total value
    this.currentOrder!.totalValue = totalProd + delivery;

    // this.currentOrder!.deliverySim = this.deliverySimList[index];

    this.newSelectedSimItem = this.deliverySimList[index];
    this.newSelectedSimItem.cepDestination = this.deliverySimListCep;
  }
}
