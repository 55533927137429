import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../../../app/models/Order';
import { Response } from '../../../app/models/Response';
import { environment } from '../../../environments/environment';
import { Register } from '../../../interactionModels/Register';
import { User } from '../../../app/models/User';
import { Login } from '../../../interactionModels/Login';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private httpClient: HttpClient){}

  registerNewUser(register: Register): Observable<Response<User>> {
    console.log('RegisterService => registerNewUser');
    return this.httpClient.post<Response<User>>(`${environment.apiUrl}user/register`, register);
  }

  /**
   * Description
   * @param {any} order:Order
   * @returns {any}
   */
  updateOrderOwnerAndStatusToActive(order: Order): Observable<Response<any>> {
    return this.httpClient.put<Response<any>>(`${environment.apiUrl}order/orderOwnerAndStatus`, order);
  }

  /**
   * Description
   * @param {any} loginContent:Login
   * @returns {any}
   */
  loginUser(loginContent: Login): Observable<Response<any>> {
    return this.httpClient.post<Response<any>>(`${environment.apiUrl}auth/login`, loginContent);
  }
}