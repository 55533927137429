// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    localStorage: {
      user: {
        token: 'elmtr-fotons-user-token',
        roles: 'elmtr-fotons-user-roles',
        picture: 'elmtr-fotons-user-picture',
        firstname: 'elmtr-fotons-user-firstname',
        lastname: 'elmtr-fotons-user-lastname',
        email: 'elmtr-fotons-user-email'
      },
      franchise: {
        brand: 'elmtr-fotons-franchise-brand'
      }
    },
    apiUrl: 'https://webservice-2484.chargedcloud.com.br/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
