/* eslint-disable prettier/prettier */

import { BaseModel } from "./BaseModel";
import { User } from "./User";
import * as hashJs from 'hash.js';

export class Password extends BaseModel {
    passwordData?: string;
    uncryptedData?: string;
    owner?: User;
    expiresAt?: number;

    constructor(passwordData: string | null = null, uncryptedData: string | null = null) {
        super();
        
        if (passwordData) {
            this.passwordData = passwordData;
        } else if (uncryptedData) {
            this.passwordData = Password.encodePassword(uncryptedData);
        }
    }

    static encodePassword(password: string): string {
        return hashJs.sha256().update(password).digest('hex');
    }
}
