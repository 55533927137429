import { Injectable } from '@angular/core';

import { environment } from "../../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Login } from '../../../../interactionModels/Login';
import { Observable } from 'rxjs';
import { Order, Response } from '../../../../app/models';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Description
   * @param {any} loginContent:Login
   * @returns {any}
   */
  loginUser(loginContent: Login): Observable<Response<any>> {
    return this.httpClient.post<Response<any>>(`${environment.apiUrl}auth/login`, loginContent);
  }

  /**
   * Description
   * @param {any} order:Order
   * @returns {any}
   */
  updateOrderOwnerAndStatusToActive(order: Order): Observable<Response<any>> {
    return this.httpClient.put<Response<any>>(`${environment.apiUrl}order/orderOwnerAndStatus`, order);
  }
}
