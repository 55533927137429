import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { Password, User, Token, Order, Response, Address } from '../../../app/models';
import { Register } from '../../../interactionModels/Register';
import { RegisterService } from './register.service';
import { from } from 'rxjs';
import { Login } from '../../../interactionModels/Login';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'elmtr-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {
  wizardStep: number = 1;

  allowStep1: boolean = true;
  allowStep2: boolean = true;
  allowStep3: boolean = true;

  registerForm: UntypedFormGroup | undefined;
  state: any | null  = null;

  constructor(private toastrService: ToastrService, private router: Router, private registerService: RegisterService){
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.state = this.router.getCurrentNavigation()?.extras.state ? this.router.getCurrentNavigation()?.extras.state : null;
    }
  }

  ngOnInit(){
    this.registerForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
      lastname: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, [Validators.required]),
      confirmpassword: new UntypedFormControl(null, [Validators.required]),

      email: new UntypedFormControl(null, [Validators.required]),
      cpf: new UntypedFormControl(null, []),
      phone: new UntypedFormControl(null, [])
    });

    if (this.state?.tempOrder && this.state?.tempUser) {
      this.registerForm.addControl('address_nickname', new UntypedFormControl('',  [Validators.required]));
      this.registerForm.addControl('street', new UntypedFormControl('',  [Validators.required]));
      this.registerForm.addControl('number', new UntypedFormControl('',  [Validators.required]));
      this.registerForm.addControl('complement', new UntypedFormControl(''));
      this.registerForm.addControl('neighborhood', new UntypedFormControl('',  [Validators.required]));
      this.registerForm.addControl('city', new UntypedFormControl('',  [Validators.required]));
      this.registerForm.addControl('cep', new UntypedFormControl('',  [Validators.required]));
    }

    if (this.state && this.state?.tempUser && this.state?.tempOrder ){
      this.toastrService.info('Ao fazer login, você está associando o pedido da página anterior ao usuário que entrar');
    }
  }


  nextStep(fromStep: number, allowed: boolean) {
    if (!allowed) {
      return;
    }
    
    switch (fromStep) {
      case 1:
        if (!this.registerForm?.getRawValue().name || !this.registerForm?.getRawValue().name) {
          this.toastrService.error('Nome e sobrenome são obrigatórios');
          return;
        }
        
        if (!this.registerForm?.getRawValue().password && !this.registerForm?.getRawValue().confirmpassword) {
          this.toastrService.error('Senha e confirmação de senha são obrigatórios');
          return;
        }

        if (this.registerForm?.getRawValue().password != this.registerForm?.getRawValue().confirmpassword) {
          this.toastrService.error('As senhas não combinam');
          return;
        }

        this.wizardStep++;
        return;
    
      case 2:
        if (!this.registerForm?.getRawValue().email) {
          this.toastrService.error('Email é um campo obrigatório');
          return;
        }

        if (this.registerForm?.getRawValue().email.lastIndexOf('@') == -1) {
          this.toastrService.error('Email tem que ser válido');
          return;
        }

        if (this.state.tempOrder && this.state.tempUser) {
          this.toastrService.info('Esse cadastro possui um pedido associado, alguns campos extras serão pedidos para a entrega.');

          this.registerForm.controls['phone'].addValidators(Validators.required);

          if (!this.registerForm?.getRawValue().phone) {
            this.toastrService.error('Campo telefone não pode estar vazio');
            return;
          }
  
          if (this.registerForm?.getRawValue().phone.length > 11) {
            this.toastrService.error('Campos unsuficientes para um número de telefone');
            return;
          }

          if (!this.registerForm?.getRawValue().cpf) {
            this.toastrService.error('Cpf não pode estar vazio');
            return;
          }

          if (this.registerForm?.getRawValue().cpf.length > 11) {
            this.toastrService.error('Campos unsuficientes para um cpf');
            return;
          }

          this.wizardStep++;
        }

        
        return;
      case 3:
      default:
        break;
    }


  }
  
  submitForm(){
    console.log('this.registerForm?.valid', this.registerForm?.valid);

    const rawFormValue = this.registerForm?.getRawValue();
      if (!this.registerForm?.valid) {
        this.toastrService.error('O formulário é inválido');
        return;
      }

      if (rawFormValue.email.lastIndexOf('@') == -1) {
        this.registerForm?.controls['email'].markAsDirty();
        this.toastrService.error('O email digitado não é válido');
        return;
      }

      if (rawFormValue.password.length < 8 ) {
        this.toastrService.error('A senha tem que ter ao menos 8 caracteres');
        return;
      }

      if (rawFormValue.password != rawFormValue.confirmpassword ) {
        this.toastrService.error('A senhas não correspondem');
        return;
      }
      

      this.toastrService.info('Processando');

      // console.log(rawFormValue);

      const newPassword = new Password(null, rawFormValue.password);
      
      const newUser = new User();
      
      newUser.password = newPassword;
      newUser.email = rawFormValue.email;
      newUser.firstname = rawFormValue.name;
      newUser.lastname = rawFormValue.lastname;


      if (this.state?.tempOrder && this.state?.tempUser) { 
        const address = new Address();
        // lat?: string;
        // long?: string;
        // aditional?: string;
        // cep?: number;

        address.aditional = null;
        address.street = rawFormValue.street;
        address.number = rawFormValue.number;
        address.complement = rawFormValue.complement;
        address.neighborhood = rawFormValue.neighborhood;
        address.city = rawFormValue.city;
        address.nickname = rawFormValue.address_nickname;
        address.cep = rawFormValue.cep;

        newUser.address = address;
      }

      const register = new Register();
      register.newUser = newUser;

      if (this.state && this.state?.tempUser && this.state?.tempOrder ) {
        register.tempUser = { token: { token: this.state.tempUser } as Token } as User;
        register.orderAttached = { externalId: this.state.tempOrder } as Order;
      }
  
      console.log('register', register);
      this.registerService.registerNewUser(register)
        .subscribe((registerResponse: Response<User>) => {
          console.log('registerResponse', registerResponse);
          if (!registerResponse.operationSuccess) {
            this.toastrService.error(registerResponse.messages[0].message);
            return;
          }
          this.toastrService.success('Novo usuário criado com sucesso. Entrando...');

          const login = new Login();
          login.email = newUser.email!;
          login.password = rawFormValue.password;
          login.encodePassword();

          this.registerService.loginUser(login)
            .subscribe((attachUserToOrder: Response<any>) => {
              if (!attachUserToOrder.operationSuccess) {
                attachUserToOrder.messages.forEach(currentMessage => {
                  this.toastrService.error(currentMessage.message)
                });

                return;
              } 

              // [TODO] change the place to save this data below make a auth service
              localStorage.setItem(environment.localStorage.user.token, attachUserToOrder.item.token.token);
              localStorage.setItem(environment.localStorage.user.roles, attachUserToOrder.item.roles);
              localStorage.setItem(environment.localStorage.user.picture, attachUserToOrder.item.picture);
              localStorage.setItem(environment.localStorage.user.firstname, attachUserToOrder.item.firstname);
              localStorage.setItem(environment.localStorage.user.lastname, attachUserToOrder.item.lastname);
              localStorage.setItem(environment.localStorage.user.email, attachUserToOrder.item.email);


              this.toastrService.success('Login realizado com sucesso');

              if (this.state && this.state?.tempUser && this.state?.tempOrder ) {
                this.router.navigateByUrl('/auth/checkout');
              } else {
                this.router.navigateByUrl('/auth');
              }
            });
        })


  }
}
