import { Component } from '@angular/core';

import { environment } from "../../../environments/environment";
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { LoginService } from "./services/login.service";
import { Login } from '../../../interactionModels/Login';
import { Observable } from 'rxjs';
import { ElementarDate, Order, Response, User } from '../../../app/models';

@Component({
  selector: 'elmtr-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  brandName: string = 'Temporary name';
  brandLogo: string | null = null;

  rotatorCurrentItem: { brandName: string, brandLogo: string } | null = null;

  loginForm: UntypedFormGroup | undefined;

  state: any | null  = null;

  constructor(private toastrService: ToastrService, private loginService: LoginService, private router: Router) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.state = this.router.getCurrentNavigation()?.extras.state ? this.router.getCurrentNavigation()?.extras.state : null;
    }
  }

  ngOnInit(): void {
    this.brandLogo = localStorage.getItem(environment.localStorage.franchise.brand);
    this.brandName = 'Temporary name';
    // this.brandLogo = localStorage.getItem(environment.localStorage.franchise.brand);

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, [Validators.required])
    });

    if (this.state && this.state.tempUser && this.state.tempOrder ){
      this.toastrService.info('Ao fazer login, você está associando o pedido da página anterior ao usuário que entrar');
    }
  }


  onSubmit(): void {
    const rawFormValue = this.loginForm?.getRawValue();
    if (!this.loginForm?.valid) {
      this.toastrService.error('O formulário é inválido');
      return;
    }

    if (rawFormValue.email.lastIndexOf('@') == -1) {
      this.loginForm?.controls['email'].markAsDirty();
      this.toastrService.error('O email digitado não é válido');
      return;
    }

    this.toastrService.info('Processando');

    const loginItem: Login = new Login(rawFormValue.email, rawFormValue.password);
    loginItem.encodePassword();

    this.loginService.loginUser(loginItem)
      .subscribe((loginResponse: Response<any>) => {
        console.log(loginResponse);
        if (!loginResponse.operationSuccess) {
          loginResponse.messages.forEach(element => {
            this.toastrService.error(element.message)
          });
          return;
        }

        // [TODO] change the place to save this data below make a auth service
        localStorage.setItem(environment.localStorage.user.token, loginResponse.item.token.token);
        localStorage.setItem(environment.localStorage.user.roles, loginResponse.item.roles);
        localStorage.setItem(environment.localStorage.user.picture, loginResponse.item.picture);
        localStorage.setItem(environment.localStorage.user.firstname, loginResponse.item.firstname);
        localStorage.setItem(environment.localStorage.user.lastname, loginResponse.item.lastname);
        localStorage.setItem(environment.localStorage.user.email, loginResponse.item.email);


        this.toastrService.success('Login realizado com sucesso');

        // tempUser: this.activatedRoute.snapshot.params['token'],
        // tempOrder: this.activatedRoute.snapshot.params['order']
        
  
        if (this.state && this.state.tempUser && this.state.tempOrder ) {

          const temporaryOrder = new Order();
          temporaryOrder.owner = { id: loginResponse.item.id } as User;
         
          temporaryOrder.externalId = this.state.tempOrder;

          // [TODO] REMOVE THIS FROM HERE, THIS SHOULD BE DONE BY THE API 
          temporaryOrder.updatedAt = new ElementarDate();
          temporaryOrder.updatedBy = { id: loginResponse.item.id } as User;

          this.loginService.updateOrderOwnerAndStatusToActive(temporaryOrder)
            .subscribe((attachUserToOrder: Response<any>) => {
              if (!attachUserToOrder.operationSuccess) {
                attachUserToOrder.messages.forEach(currentMessage => {
                  this.toastrService.error(currentMessage.message)
                });
              } else {
                this.router.navigateByUrl('/auth/checkout');
              }
            });
          return;
        }

        this.router.navigateByUrl('/auth');
        return;
        
      });
  }


  clickRedirect(direction: 'register'){
    
    switch (direction) {
      case 'register':
        this.router.navigateByUrl('/register', { state: {...this.state}});
        break;
    
      default:
        break;
    }
  }
}
