/* eslint-disable prettier/prettier */

import { BaseModel } from "./BaseModel";
import { OrderStatus } from "./OrderStatus";
import { ProductsOrder } from "./ProductsOrder";
import { User } from "./User";
import { Address } from "./Address";
import { Delivery } from "./Delivery";
import { SimResponse } from "./SimResponse";

export class Order extends BaseModel {
    delivery?: Delivery;
    deliveryAddress?: Address;
    deliverySim?: SimResponse;
    deliveryUid?: string;
    deliveryValue?: number;
    orderStatus?: OrderStatus;
    owner?: User;
    productsList?: ProductsOrder[];
    totalProductsValue?: number;
    totalValue?: number;
}
